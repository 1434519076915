import '../scss/main.scss';

//replacement for babel-polyfill to use if polyfills are needed
// import 'core-js/stable';
// import "regenerator-runtime/runtime";

import $ from 'jquery';
import browserUpdate from 'browser-update';
import './fonts';


(function ($) {
    var mqBreakpoints = {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200
        },
        $window = $(window),
        windowWidth = $window.innerWidth();


    /*
     * Function
     * Expand aria-controls elements
     */
    var ariaControls = function () {
        if ($('.js-aria-control').length > 0) {

            var $trigger = $();

            $(document).on('click', '.js-aria-control', function (e) {
                e.preventDefault();

                var $_this = $(this),
                    $_targetEl = $('#' + $_this.attr('aria-controls')),
                    state = $_this.attr('aria-expanded') !== 'false';

                $_this.attr('aria-expanded', !state);
                $_targetEl.attr('aria-hidden', state);
            });
        }
    };


    /*
     * Function
     * Check visibility for ARIA controlled element and add attributes (hidden and expanded)
     */
    var checkVisibility = function (id, breakpoint) {
        var $_el = $('#' + id),
            controller = $('[aria-controls=' + id + ']');

        if ($('window').innerWidth() <= breakpoint) {
            $_el.attr('aria-hidden', true);
            controller.attr('aria-expanded', false);
        } else {
            $_el.attr('aria-hidden', false);
            controller.attr('aria-expanded', true);
        }
    };


    /*
     * Function
     * When window is resized, re-check visibility
     */
    var watchVisibility = function () {
        // If orientation change (for mobile and tablet)
        window.addEventListener("orientationchange", function () {
            // Change windowWidth
            windowWidth = window.innerWidth();
        }, false);

        $(window).resize(function () {
            // Check if width really change (mobile consider scrolling as a width change)
            if ($window.innerWidth() !== windowWidth) {

                checkVisibility('main-nav', mqBreakpoints.tablet);
            }
        });
    };

    $(document).ready(function () {
        ariaControls();
        checkVisibility('main-nav', mqBreakpoints.lg);
        watchVisibility();
        browserUpdate({
            required: {
                e:-2,
                f:-3,
                o:-3,
                s:10.1,
                c:"64.0.3282.16817",
                samsung: 7.0,
                vivaldi: 1.2
            },
            container: document.body
        });
    });

})($);

